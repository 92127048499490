import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import { ParallaxProvider } from 'react-scroll-parallax'

import '../assets/index.scss'

import Image from '../components/Image'
import Videos from '../components/Videos'
import Navbar from '../components/Navbar'
import Footer from '../components/Footer'
import Layout from '../components/Layout'
import Contact from '../components/Contact'
import { HTMLContent } from '../components/Content'
import { ArrowButton } from '../components/Button'

export const SecretPageTemplate = ({
	title,
	body,
	cta,
	promotion,
	videos,
	subheading,
	contactForm,
	email,
	phoneNumber,
	formUrl,
	contactReceivedMessage,
	menuItems
}) => {
	return (
		<div className="page">
			<div className="page__header page__header--extras">
				<Navbar defaultDark={true} fixed={true} pageMenuItems={menuItems} />
				<main >
					<span className="heading" style={!cta && !subheading ? { paddingBottom: '6rem' } : {}}>{title}</span>
					{
						subheading &&
						<p className="subheading">{subheading}</p>
					}
					{
						cta &&
						<a href={cta.href}>
							<button className="primary-button">
								{cta.text}
							</button>
						</a>
					}
				</main>
			</div>
			{
				promotion &&
				<section id="promotion" className="alt-section">
					<div className="container about__content">
						<Image image={promotion.image} className="about__image promotion__image" />
						<div>
							<p>{promotion.text}</p>
							<ArrowButton text={promotion.cta.text} href={promotion.cta.href} />
						</div>
					</div>
				</section>
			}
			{videos && videos.length > 0 &&
				<Videos className="secret-page__videos" videos={videos} />
			}
			<section id="content" className="section">
				<div className="container">
					<HTMLContent className="secret-page__content" content={body} />
				</div>
			</section>
			{contactForm &&
				<Contact
					phoneNumber={phoneNumber}
					email={email}
					contactReceivedMessage={contactReceivedMessage}
					formUrl={formUrl} />
			}
			<Footer pageMenuItems={menuItems} />
		</div>
	)
}

SecretPageTemplate.propTypes = {
	image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
	title: PropTypes.string,
	body: PropTypes.string
}

const ctaUrlToHref = (obj) => {
	if (obj && !obj.cta.href && obj.cta.file) {
		obj.cta.href = obj.cta.file.publicURL
	}
	return obj
}

const SecretPage = ({ data }) => {
	const { frontmatter, html } = data.markdownRemark
	frontmatter.promotion = ctaUrlToHref(frontmatter.promotion)

	return (
		<ParallaxProvider>
			<Layout
				pageTitle={frontmatter.title}>
				<SecretPageTemplate
					title={frontmatter.title}
					subheading={frontmatter.subheading}
					cta={frontmatter.cta}
					promotion={frontmatter.promotion}
					videos={frontmatter.videos}
					menuItems={frontmatter.menuItems ?? []}
					body={html}
					contactForm={frontmatter.contactForm}
					email={data.formData.frontmatter.email}
					phoneNumber={data.formData.frontmatter.phoneNumber}
					formUrl={data.formData.frontmatter.formUrl}
					contactReceivedMessage={data.formData.frontmatter.contactReceivedMessage}
				/>
			</Layout>
		</ParallaxProvider>
	)
}

SecretPage.propTypes = {
	data: PropTypes.shape({
		markdownRemark: PropTypes.shape({
			frontmatter: PropTypes.object,
			html: PropTypes.string
		}),
	}),
}

export default SecretPage

export const pageQuery = graphql`
	query SecretPageById($id: String!) {
		formData: markdownRemark(frontmatter: { templateKey: {eq: "single-page"} }) {
			frontmatter {
				phoneNumber
	            email
	            contactReceivedMessage
	            formUrl
			}
		}
		markdownRemark(id: { eq: $id }) {
			frontmatter {
			  title
			  menuItems {
				  title
				  link
				  children {
				    title
				    link
				  }
			  }
			  subheading
			  contactForm
			  cta {
				text
				href
			  }
			  promotion {
				image {
				  childImageSharp {
					fluid(maxWidth: 1000) {
						...GatsbyImageSharpFluid
					}
				  }
				}
				cta {
				  text
				  file {
					publicURL
				  }
				}
				text
			  }
			  videos {
				  title
				  videoURL
			  }
			}
			html
		}
	}
`