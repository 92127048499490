import React from 'react'

export const ArrowButton = ({text, href}) => {
    return (
        <div className="about__button secondary-button">
            <a href={href}>
                <div className="arrow-button">
                    <span className="arrow-button__inner">{text}</span>
                    <svg viewBox="0 0 481.5 293.5"><defs></defs><path fill="currentColor" d="M468.6 59.6l-46.7-46.7-187.2 187.2L46.7 12 0 58.7l188.1 188 46.6 46.7.1.1 46.7-46.7-.1-.1z"></path></svg>
                </div>  
            </a>
        </div>
    )
}